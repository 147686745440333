
import { logoGithub, mail, chevronBack, chevronForward } from 'ionicons/icons';
import { IonButton, IonContent, IonIcon, IonPage, IonSlide, IonSlides } from '@ionic/vue';
import { defineComponent, Ref, ref } from 'vue';

export default defineComponent({
  name: 'Help',
  components: {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonSlide,
    IonSlides,
  },
  setup() {
    const publicPath = process.env.BASE_URL;
    const ionSlides: Ref<typeof IonSlides | null> = ref(null);
    const nextSlide = () => ionSlides.value?.$el.slideNext();
    const prevSlide = () => ionSlides.value?.$el.slidePrev();
    return {
      ionSlides,
      nextSlide,
      prevSlide,
      logoGithub,
      publicPath,
      mail,
      chevronBack,
      chevronForward,
    };
  },
});
